import React, { useState } from "react";
import { BrowserRouter as Router, useHistory, Link } from "react-router-dom";
import google_normal from "../img/btn_google_signin_light_normal_web.png";
import "./Login.css";
var showButtons = true;
export default function Login(props) {
  const [Loading, setLoading] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const firebase = props.firebase;

  const loginWithGoogle = async () => {
    try {
      showButtons = false;
      props.toggleLoading();
      var googleProvider = new firebase.auth.GoogleAuthProvider();
      await firebase.auth().signInWithRedirect(googleProvider);
    } catch (error) {
      alert(error);
    }
  };

  const loginWithTwitter = async () => {
    try {
      showButtons = false;
      props.toggleLoading();
      setLoading(true);
      var twitterProvider = new firebase.auth.TwitterAuthProvider();
      await firebase.auth().signInWithRedirect(twitterProvider);
    } catch (error) {
      alert(error);
    }
  };

  let history = useHistory();

  if (props.user) {
    history.push("/home");
    return <p>please jump to neutodo.com/home</p>;
  } else {
    return (
      <div className="Login">
        <h1>Log in</h1>
        {props.loading && <div>Now Loading...</div>}
        {showButtons && (
          <section>
            <h2>ログインしてはじめる</h2>
            {isAgree && (
              <div>
                <button onClick={() => loginWithTwitter()}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                  </svg>{" "}
                  Log in with Twitter
                </button>
                <img
                  src={google_normal}
                  alt="google login"
                  onClick={() => loginWithGoogle()}
                />
                <p>
                  <Link to="/policy" style={{ color: "gray" }}>
                    利用規約・プライバシーポリシー
                  </Link>
                  に同意しました。
                </p>
              </div>
            )}
            {!isAgree && (
              <div>
                <div className="Links">
                  <label>
                    <input
                      type="checkbox"
                      value="isAgree"
                      onClick={(e) => setIsAgree(!isAgree.value)}
                    />
                  </label>
                  {"　"}
                  <Link to="/policy">利用規約・プライバシーポリシー</Link>
                  に同意する。
                </div>
              </div>
            )}

            {isAgree && (
              <p>
                Twitterでのログインをおすすめします。今後Twitterでの機能の追加を予定しています。
              </p>
            )}
          </section>
        )}
      </div>
    );
  }
}
