import React from "react";

export default function Policy() {
  return (
    <div style={{ padding: "10px 10% 10px 10%" }}>
      <h1>利用規約・プライバシーポリシー</h1>
      Neutodo.comをご覧頂きありがとうございます。
      <p>
        利用者の皆様におかれましては、本サービスの利用にあたって、次の利用規約・プライバシーポリシーへの同意が必須となっております。
      </p>
      <h2>利用規約</h2>
      この利用規約（以下，「本規約」といいます。）は，
      <a href="https://toyo.dev">Toyoshima Hidenori</a>
      （以下，「本サービス提供者」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。
      <h3>第1条（適用）</h3>
      <ol>
        <li>
          本規約は，ユーザーと本サービス提供者との間の本サービスの利用に関わる一切の関係に適用されるものとします。
        </li>
        <li>
          本サービス提供者は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。
        </li>
        <li>
          本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。
        </li>
      </ol>
      <h3>第2条（利用登録）</h3>
      <ol>
        <li>
          本サービスにおいては，登録希望者が本規約に同意の上，本サービス提供者の定める方法によって利用登録を申請し，本サービス提供者がこれを承認することによって，利用登録が完了するものとします。
        </li>
        <li>
          本サービス提供者は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。
          <ol>
            <li>利用登録の申請に際して虚偽の事項を届け出た場合</li>
            <li>本規約に違反したことがある者からの申請である場合</li>
            <li>
              その他，本サービス提供者が利用登録を相当でないと判断した場合
            </li>
          </ol>
        </li>
      </ol>
      <h3>第3条（ユーザーIDおよびパスワードの管理）</h3>
      <ol>
        <li>
          ユーザーは，自己の責任において，本サービスのユーザーIDおよびパスワードを適切に管理するものとします。
        </li>
        <li>
          ユーザーは，いかなる場合にも，ユーザーIDおよびパスワードを第三者に譲渡または貸与し，もしくは第三者と共用することはできません。本サービス提供者は，ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には，そのユーザーIDを登録しているユーザー自身による利用とみなします。
        </li>
        <li>
          ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は，本サービス提供者に故意又は重大な過失がある場合を除き，本サービス提供者は一切の責任を負わないものとします。
        </li>
      </ol>
      <h3>第4条（利用料金および支払方法）</h3>
      <ol>
        <li>
          ユーザーは，本サービスの有料部分の対価として，本サービス提供者が別途定め，本ウェブサイトに表示する利用料金を，本サービス提供者が指定する方法により支払うものとします。
        </li>
        <li>
          ユーザーが利用料金の支払を遅滞した場合には，ユーザーは年14．6％の割合による遅延損害金を支払うものとします。
        </li>
      </ol>
      <h3>第5条（禁止事項）</h3>
      <p>
        ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。
      </p>
      <ol>
        <li>法令または公序良俗に違反する行為</li>
        <li>犯罪行為に関連する行為</li>
        <li>
          本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為
        </li>
        <li>
          本サービス提供者，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為
        </li>
        <li>本サービスによって得られた情報を商業的に利用する行為</li>
        <li>本サービス提供者のサービスの運営を妨害するおそれのある行為</li>
        <li>不正アクセスをし，またはこれを試みる行為</li>
        <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
        <li>不正な目的を持って本サービスを利用する行為</li>
        <li>
          本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為
        </li>
        <li>他のユーザーに成りすます行為</li>
        <li>
          本サービス提供者が許諾しない本サービス上での宣伝，広告，勧誘，または営業行為
        </li>
        <li>面識のない異性との出会いを目的とした行為</li>
        <li>
          本サービス提供者のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
        </li>
        <li>その他，本サービス提供者が不適切と判断する行為</li>
      </ol>
      <h3>第6条（本サービスの提供の停止等）</h3>
      <ol>
        <li>
          本サービス提供者は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
          <ol>
            <li>
              本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
            </li>
            <li>
              地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合
            </li>
            <li>コンピュータまたは通信回線等が事故により停止した場合</li>
            <li>
              その他，本サービス提供者が本サービスの提供が困難と判断した場合
            </li>
          </ol>
        </li>
        <li>
          本サービス提供者は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。
        </li>
      </ol>
      <h3>第7条（利用制限および登録抹消）</h3>
      <ol>
        <li>
          本サービス提供者は，ユーザーが以下のいずれかに該当する場合には，事前の通知なく，ユーザーに対して，本サービスの全部もしくは一部の利用を制限し，またはユーザーとしての登録を抹消することができるものとします。
          <ol>
            <li>本規約のいずれかの条項に違反した場合</li>
            <li>登録事項に虚偽の事実があることが判明した場合</li>
            <li>料金等の支払債務の不履行があった場合</li>
            <li>本サービス提供者からの連絡に対し，一定期間返答がない場合</li>
            <li>本サービスについて，最終の利用から一定期間利用がない場合</li>
            <li>
              その他，本サービス提供者が本サービスの利用を適当でないと判断した場合
            </li>
          </ol>
        </li>
        <li>
          本サービス提供者は，本条に基づき本サービス提供者が行った行為によりユーザーに生じた損害について，一切の責任を負いません。
        </li>
      </ol>
      <h3>第8条（退会）</h3>
      <p>
        ユーザーは，本サービス提供者の定める退会手続により，本サービスから退会できるものとします。
      </p>
      <h3>第9条（保証の否認および免責事項）</h3>
      <ol>
        <li>
          本サービス提供者は，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
        </li>
        <li>
          本サービス提供者は，本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし，本サービスに関する本サービス提供者とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。
        </li>
        <li>
          前項ただし書に定める場合であっても，本サービス提供者は，本サービス提供者の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（本サービス提供者またはユーザーが損害発生につき予見し，または予見し得た場合を含みます。）について一切の責任を負いません。また，本サービス提供者の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は，ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。
        </li>
        <li>
          本サービス提供者は，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。
        </li>
      </ol>
      <h3>第10条（サービス内容の変更等）</h3>
      <p>
        本サービス提供者は，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。
      </p>
      <h3>第11条（利用規約の変更）</h3>
      <p>
        本サービス提供者は，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお，本規約の変更後，本サービスの利用を開始した場合には，当該ユーザーは変更後の規約に同意したものとみなします。
      </p>
      <h3>第12条（個人情報の取扱い）</h3>
      <p>
        本サービス提供者は，本サービスの利用によって取得する個人情報については，本サービス提供者「プライバシーポリシー」に従い適切に取り扱うものとします。
      </p>
      <h3>第13条（通知または連絡）</h3>
      <p>
        ユーザーと本サービス提供者との間の通知または連絡は，本サービス提供者の定める方法によって行うものとします。本サービス提供者は,ユーザーから,本サービス提供者が別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にユーザーへ到達したものとみなします。
      </p>
      <h3>第14条（権利義務の譲渡の禁止）</h3>
      <p>
        ユーザーは，本サービス提供者の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。
      </p>
      <h3>第15条（準拠法・裁判管轄）</h3>
      <ol>
        <li>本規約の解釈にあたっては，日本法を準拠法とします。</li>
        <li>
          本サービスに関して紛争が生じた場合には，本サービス提供者の本店所在地を管轄する裁判所を専属的合意管轄とします。
        </li>
      </ol>
      <h3>第16条（著作権等知的財産権）</h3>
      本サービス内の文章、写真その他の知的財産権は「本サービス提供者」に帰属します。利用者は、著作権法により認められている引用の範囲である場合を除き、無断で使用（複製、送信、譲渡、二次利用等を含む）することを禁じます。
      本サービス内の内容の全てあるいは一部を引用の範囲を超えて無断で使用した場合、使用料を請求することがあります。
      <p class="tR">以上</p>
      <h2>プライバシーポリシー</h2>
      <a href="https://toyo.dev">Toyoshima Hidenori</a>{" "}
      （以下，「本サービス提供者」といいます。）は，本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。
      <h3>第1条（個人情報）</h3>
      「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報及び容貌，指紋，声紋にかかるデータ，及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
      本サービスでは、認証情報に含まれるユーザ名やメールアドレス等、「個人情報」を利用します。
      <h3>第2条（個人情報の収集方法）</h3>
      本サービス提供者は，ユーザーが利用登録をする際に氏名，生年月日，住所，電話番号，メールアドレス，銀行口座番号，クレジットカード番号，運転免許証番号などの個人情報をお尋ねすることがあります。また，ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を,製作者の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。
      また、本サービスではGoogle Analytics、Google
      Adsenseを利用しており、この利用のためにCookieを使用してデータを収集しています。
      <h3>第3条（個人情報を収集・利用する目的）</h3>
      サービス提供者が個人情報を収集・利用する目的は，以下のとおりです。
      <ul style={{ listStyleType: "circle" }}>
        <li>・本サービス提供サービスの提供・運営のため</li>
        <li>
          ・
          ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）
        </li>
        <li>
          ・
          ユーザーが利用中のサービスの新機能，更新情報，キャンペーン等及び本サービス提供者が提供する他のサービスの案内のメールを送付するため
        </li>
        <li>・ メンテナンス，重要なお知らせなど必要に応じたご連絡のため</li>
        <li>
          ・
          利用規約に違反したユーザーや，不正・不当な目的でサービスを利用しようとするユーザーの特定をし，ご利用をお断りするため
        </li>
        <li>
          ・
          ユーザーにご自身の登録情報の閲覧や変更，削除，ご利用状況の閲覧を行っていただくため
        </li>
        <li>・有料サービスにおいて，ユーザーに利用料金を請求するため</li>
        <li>・本サービスの改善及びユーザー体験向上のため</li>
        <li>・上記の利用目的に付随する目的</li>
      </ul>
      <h3>第4条（利用目的の変更）</h3>
      本サービス提供者は，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，個人情報の利用目的を変更するものとします。
      利用目的の変更を行った場合には，変更後の目的について，本サービス提供者所定の方法により，ユーザーに通知し，または本ウェブサイト上に公表するものとします。
      <h3>第5条（個人情報の第三者提供）</h3>
      本サービス提供者は，次に掲げる場合を除いて，あらかじめユーザーの同意を得ることなく，第三者に個人情報を提供することはありません。ただし，個人情報保護法その他の法令で認められる場合を除きます。
      <ul>
        <li>
          人の生命，身体または財産の保護のために必要がある場合であって，本人の同意を得ることが困難であるとき
        </li>
        <li>
          公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって，本人の同意を得ることが困難であるとき
        </li>
        <li>
          国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって，本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
        </li>
        <li>
          予め次の事項を告知あるいは公表し，かつ本サービス提供者が個人情報保護委員会に届出をしたとき
        </li>
        <ul>
          <li>
            利用目的に第三者への提供を含むこと 第三者に提供されるデータの項目
          </li>
          <li>第三者への提供の手段または方法</li>
          <li>本人の求めに応じて個人情報の第三者への提供を停止すること</li>
          <li>本人の求めを受け付ける方法</li>
        </ul>
        <li>
          前項の定めにかかわらず，次に掲げる場合には，当該情報の提供先は第三者に該当しないものとします。
        </li>
        <ul>
          <li>
            本サービス提供者が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
          </li>
          <li>
            合併その他の事由による事業の承継に伴って個人情報が提供される場合
          </li>
          <li>
            個人情報を特定の者との間で共同して利用する場合であって，その旨並びに共同して利用される個人情報の項目，共同して利用する者の範囲，利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について，あらかじめ本人に通知し，または本人が容易に知り得る状態に置いた場合
          </li>
        </ul>
      </ul>
      <h3>第6条（個人情報の開示）</h3>
      本サービス提供者は，本人から個人情報の開示を求められたときは，本人に対し，遅滞なくこれを開示します。ただし，開示することにより次のいずれかに該当する場合は，その全部または一部を開示しないこともあり，開示しない決定をした場合には，その旨を遅滞なく通知します。なお，個人情報の開示に際しては，1件あたり1，000円の手数料を申し受けます。
      本人または第三者の生命，身体，財産その他の権利利益を害するおそれがある場合
      本サービス提供者の業務の適正な実施に著しい支障を及ぼすおそれがある場合
      その他法令に違反することとなる場合
      前項の定めにかかわらず，履歴情報および特性情報などの個人情報以外の情報については，原則として開示いたしません。
      <h3>第7条（個人情報の訂正および削除）</h3>
      ユーザーは，本サービス提供者の保有する自己の個人情報が誤った情報である場合には，本サービス提供者が定める手続きにより，本サービス提供者に対して個人情報の訂正，追加または削除（以下，「訂正等」といいます。）を請求することができます。
      本サービス提供者は，ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の訂正等を行うものとします。
      本サービス提供者は，前項の規定に基づき訂正等を行った場合，または訂正等を行わない旨の決定をしたときは遅滞なく，これをユーザーに通知します。
      <h3>第8条（個人情報の利用停止等）</h3>
      本サービス提供者は，本人から，個人情報が，利用目的の範囲を超えて取り扱われているという理由，または不正の手段により取得されたものであるという理由により，その利用の停止または消去（以下，「利用停止等」といいます。）を求められた場合には，遅滞なく必要な調査を行います。
      前項の調査結果に基づき，その請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の利用停止等を行います。
      本サービス提供者は，前項の規定に基づき利用停止等を行った場合，または利用停止等を行わない旨の決定をしたときは，遅滞なく，これをユーザーに通知します。
      前2項にかかわらず，利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって，ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は，この代替策を講じるものとします。
      <h3>第9条（プライバシーポリシーの変更）</h3>
      本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，ユーザーに通知することなく，変更することができるものとします。
      本サービス提供者が別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。
      <h3>第10条（お問い合わせ窓口）</h3>
      本ポリシーに関するお問い合わせは，下記の窓口までお願いいたします。
      <p>担当者名 : Toyoshima Hidenori</p>
      <p>Eメールアドレス : support@neutodo.com</p>
      <p>担当者Twitterアカウント:@toyoshima_hi</p>
    </div>
  );
}
